.loading {
  display: none;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  position: fixed;
  inset: 0;

  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.6);
}

.loading div {
  width: 3rem; 
  height: 3rem;
}