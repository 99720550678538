:root {
  --clr-primary: #f5f5f5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, 
body {
  height: 100%;
}

p {
  margin: 0;
  margin-bottom: 0;  
}

img[alt=markdown-img] { 
  max-width: 100%; 
  border: 1px solid black;
}
